export const datetimeFormats = {
  ru: {
    short: {
      year: 'numeric' as const,
      month: 'short' as const,
      day: 'numeric' as const,
    },
    long: {
      year: 'numeric' as const,
      month: 'long' as const,
      day: 'numeric' as const,
    },
    time: {
      hour: '2-digit' as const,
      minute: '2-digit' as const,
    },
  },
}

export const numberFormats = {
  ru: {
    currency: {
      style: 'currency',
      currency: 'RUB',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
}

export function customRule(choice: number, choicesLength: number) {
  if (choice === 0) {
    return 0
  }

  const teen = choice % 100 > 10 && choice % 100 < 20
  const endsWithOne = choice % 10 === 1
  if (!teen && endsWithOne) {
    return 1
  }
  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2
  }

  return choicesLength < 4 ? 2 : 3
}

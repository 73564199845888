import {
  datetimeFormats,
  numberFormats,
  customRule,
} from './modules/i18n/options'

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'ru',
  datetimeFormats,
  numberFormats,
  pluralRules: {
    ru: customRule,
  },
}))
